export default function SafetyRecord() {
    return (
      <div>
        <section id="services">
          <div class="container">
            <div class="section-header">
              <h2>SAFETY RECORDS AND AUDITS </h2>
              <p className="lead">
                The following safety records shall be provided.
              </p>
  
              <div className="table-responsive">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td valign="top">
                      <p align="center">
                        <strong>CLAUSE</strong>
                      </p>
                    </td>
                    <td valign="top">
                      <p align="center">
                        <strong>RECORD</strong>
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        <strong>RESPONSIBILITY</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Management Responsibility</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>Induction Register</p>
                    </td>
                    <td valign="top">
                      <p>Project HES Manager, HES Representative</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Safety Systems and Strategy</p>
                    </td>
                    <td valign="top">
                      <p>Project HES Management Plan Training Register</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>HES Representative</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Process Control</p>
                    </td>
                    <td valign="top">
                      <p>JSEA Register JSEA Safe Work Instructions</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>HES Representative</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Safety Inspection</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>Plant Maintenance Schedule</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>Project Engineer</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Investigation and Corrective Action</p>
                    </td>
                    <td valign="top">
                      <p>Incident Reports HES Incident Tracking Register</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>HES Representative</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Handling Storage Delivery</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>Material Safety Data Sheets</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>HES Representative</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Audits</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>Audit Reports</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>HES Representative</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p></p>
                      <p>Training</p>
                    </td>
                    <td valign="top">
                      <p>Induction Register Tool Box Attendance Records</p>
                    </td>
                    <td valign="top">
                      <p></p>
                      <p>HES Representative</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  