import "./loading.css";
import { Spinner } from "react-bootstrap";

export default function Loading({ text }) {
  return (
    <div className="loading">
      <div style={{ textAlign: "center" }}>
        <Spinner className="spin" animation="grow" />
        <p style={{ marginTop: "30px" }}>
          {" "}
          {text ? text : "Picture loading, please wait..."}
        </p>
      </div>
    </div>
  );
}
