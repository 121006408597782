export default function ManagementCommitment() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>MANAGEMENT COMMITMENT</h2>
            <p className="lead">
              ACK Power Technology employee's management is committed to health
              and safety welfare of all ACK Power Technology Engineering Works
              employees and will demonstrate leadership by achieving the highest
              possible standards in both occupational and natural environment
              ACK Power Technology Management will demonstrate its commitment to
              HES management by:
            </p>

            <ul style={{marginTop:"10px"}} className="lead">
              <li>
                Conducting a field visit fortnightly (14 days) and completing a
                field verification activity (Inspection, audit,)
              </li>
              <li>Participating in fortnightly Project HES meeting.</li>
              <li>
                Leading at least one project safety toolbox meeting per month.
              </li>
              <li>
                Making aware during Orientation meeting, Toolbox meetings and
                site visits that any employee of ACK Power Technology has the
                authority to stop work when an imminent hazard to persons,
                property or the environment is identified and executing this
                Stop Work Authority by the management in event of an unsafe
                condition or behavior is observed during site visits.
              </li>
            </ul>

            <h3>EMPLOYEES COMMITMENT</h3>

            <ul style={{marginTop:"10px"}} className="lead">
              <li>
                Ensuring that decisions and practices are consistent with the
                stated policy and objectives (refer to the ACK Power Technology
                Policy statements).
              </li>
              <li>Making adequate resources available.</li>
              <li>
                Participating in construction hazard workshops and safety
                reviews.
              </li>
              <li>Discussing safety with supervisors/employees.</li>
              <li>Visiting all work areas regularly.</li>
              <li>Wearing the correct Personal Protective Equipment (PPE).</li>
              <li>
                Carrying out safety observations and giving immediate feedback.
              </li>
              <li>
                Taking disciplinary action for violations of safety rules.
              </li>
              <li>
                Ensuring that safety is not compromised to meet schedule or
                budget.
              </li>
              <li>Making safety an agenda item at formalized meetings.</li>
              <li>
                Holding regular safety meetings (Toolbox and Project
                Management).
              </li>
              <li>
                Reviewing toolbox meeting minutes. Ensuring that all incidents
                are reported and investigated.
              </li>
              <li>
                Reviewing incident investigations and following up action items.
              </li>
              <li>Participating in regular inspections and audits.</li>
              <li>Executing Stop Work Authority in event of an unsafe work.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
