import { useState,useEffect } from "react";
import Slider from "react-slick";
import "./back.css"


export default function BackgroundCarousel() {
    const [listOfImages, setListOfImages] = useState([]);
  
    useEffect(() => {
      const importAll = (r) => r.keys().map(r);
      setListOfImages(
        importAll(
          require.context("../../media/all", false, /\.(PNG|png|jpe?g|svg)$/)
        )
      );
    }, []);
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 0,
      autoplay: true,
      fade: true,
    };
  
    return (
      <div>
        <Slider className="background-carousel" {...settings}>
          {listOfImages.map((url) => (
            <div className="slide">
              <img className="sliderImage" src={url} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }