export default function ProjectHealth() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>Project Health, Safety and Environment Plan</h2>
            <p className="lead">
              ACK Power Technology Works has in place Health and Safety Policy
              Statement, Environmental Policy Statement, Drugs and Alcohol
              Policy statement and a HSE plan template covering several HSE
              related issues. A project specific HES Management Plan was
              developed and further revised on award of contract based on this
              template covering the HSE issues related to the project and/or in
              compliance with the requirements of Client. ACK Power Technology
              will operate a safety system that provides an integrated, closed
              loop approach to safety management that provides for but is not
              limited to:
            </p>
            <ul style={{marginTop:"10px"}} className="lead">
              <li> Personnel Selection.</li>
              <li>Competency and Training.</li>
              <li>Health Safety and Environmental Management Training.</li>
              <li>Management Engaging in Discussion with the Workforce.</li>
              <li>Hazard Identification and Risk Assessment.</li>
              <li>
                Emergency Preparedness Promoting Proactive Attitude Towards
                Reduction Of “At Risk Behavior & Actions”
              </li>
              <li>
                Identification and Reporting of Lead Indicators (Near Misses &
                Corrective Actions)
              </li>
              <li>
                Incident Reporting and Investigation Injury Management and
                Return to Work
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
