import "./mytable.css"

export default function Hazard() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>HAZARD IDENTIFICATION AND RISK ASSESSMENT</h2>
            <p className="lead">
              ACK Power Technology Project Management team will conduct a
              construction hazard analysis workshop prior to commencing
              construction activities. Hazard analysis should include
              Engineering, Construction and Operational personnel where
              applicable.
            </p>

            <p className="lead">
              ACK Power Technology Project Management team shall identify
              hazards and risks using a systematic approach. Hazard
              identification and reviews are to be conducted throughout the
              project lifetime; outcomes from these will be incorporated into
              action registers.
            </p>
            <p className="lead">
              A Hazard Review will be performed on any project work that has the
              potential to affect or create a new Major Accident Event (MAE). A
              MAE is any one incident with the potential to cause a fatality, or
              significant permanent environmental damage. MAE's where identified
              will be recorded in the Hazard Register.
            </p>
            <p className="lead">
              ACK Power Technology Project Management team will ensure that a
              Pre-Construction Hazard Assessment is performed on any work
              activity that could be classified as hazardous.
            </p>
            <p className="lead">
              ACK Power Technology shall provide if any temporary protective
              shielding is required to protect construction activities from
              nearby operations. ACK Power Technology shall provide for, and
              maintain, all signs, lights, barriers and traffic barricades
              needed to inform drivers of hazards to guide traffic safely past
              temporary obstruction and to protect workmen.
            </p>
            <p className="lead">
              Daily work activities shall be subjected to a Job Safety &
              Environment Analysis (JSEA) involving all those taking part in the
              work activity. General work activities that require more detailed
              guidance shall be subjected to a Safe Work Instruction (SWI).
              Following Hazard assessment, possible control measures must be
              defined for situations of unacceptable risk. A hierarchy of
              control measures to eliminate or minimize the risk should be
              followed in the priority order noted and consists of:
            </p>

            <ul className="lead">
              <li>Elimination</li>
              <li>Substitution</li>
              <li>Isolation</li>
              <li>Engineering controls</li>
              <li>Administrative controls</li>
              <li>Use of personal protective equipment</li>
            </ul>

            <p className="lead">
              Each of these controls should be applied in the above order as far
              as possible to reduce the risk to as Low as Reasonably Practical
              (ALARP). It may be necessary to use a combination of these to
              eliminate the risk or reduce it to an acceptable level.
            </p>

            <div className="table-responsive">
              <table className="table table-striped  MyTable">
                <tbody>
                  <tr style={{backgroundColor:"grey"}}>
                    <td colspan="2">
                      <strong>POTENTIAL</strong>
                      <strong>&nbsp;</strong>
                      <strong>SEVERITY</strong>
                      <strong>&nbsp;</strong>
                      <strong>BY</strong>
                      <strong>&nbsp;</strong>
                      <strong>CATEGORY</strong>
                    </td>
                    <td colspan="4">
                      <strong>LIKELIHOOD</strong>
                      <strong>&nbsp;</strong>
                      <strong>OF</strong>
                      <strong>&nbsp;</strong>
                      <strong>OCCURRENCE</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Personal</strong>
                      <strong>&nbsp;</strong>
                      <strong>Injury</strong>
                    </td>
                    <td>
                      <strong>Environment</strong>
                    </td>
                    <td>
                      <strong>Cost</strong>
                      <strong>&nbsp;</strong>
                      <strong>of</strong>
                      <strong>&nbsp;</strong>
                      <strong>Incident</strong>
                    </td>
                    <td>
                      <strong>Improbable (Not</strong>
                      <strong>&nbsp;</strong>
                      <strong>Known to Have</strong>
                      <strong>&nbsp;</strong>
                      <strong>Occurred)</strong>
                    </td>
                    <td>
                      <strong>Occasional&nbsp;</strong>
                      <strong>(Annual</strong>
                      <strong>&nbsp;</strong>
                      <strong>Occurrence)</strong>
                    </td>
                    <td>
                      <strong>Frequent (Weekly</strong>
                      <strong>&nbsp;</strong>
                      <strong>Occurrence)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Level</strong>
                      <strong>&nbsp;</strong>
                      <strong>1</strong>
                      <strong>&nbsp;</strong>
                      <strong>First</strong>
                      <strong>&nbsp;</strong>
                      <strong>Aid</strong>
                    </td>
                    <td>
                      <strong>Minor</strong>
                      <strong>&nbsp;</strong>
                      <strong>Level</strong>
                      <strong>&nbsp;</strong>
                      <strong>1</strong>
                      <strong>&nbsp;</strong>
                      <strong>incidents</strong>
                    </td>
                    <td>
                      <strong>&lt;</strong>
                      <strong>&nbsp;</strong>
                      <strong>$</strong>
                      <strong>&nbsp;</strong>
                      <strong>100,000</strong>
                    </td>
                    <td style={{backgroundColor:"green"}}>
                      <strong>L</strong>
                    </td>
                    <td style={{backgroundColor:"green"}}>
                      <strong>L</strong>
                    </td>
                    <td style={{backgroundColor:"yellow"}}>
                      <strong>M</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Level</strong>
                      <strong>&nbsp;</strong>
                      <strong>2</strong>
                      <strong>&nbsp;</strong>
                      <strong>LTI/Recordable</strong>
                      <strong>&nbsp;</strong>
                      <strong>Injury</strong>
                    </td>
                    <td>
                      <strong>Medium Level&nbsp;</strong>
                      <strong>2</strong>
                      <strong>&nbsp;</strong>
                      <strong>incidents</strong>
                    </td>
                    <td>
                      <strong>$</strong>
                      <strong>&nbsp;</strong>
                      <strong>100,000</strong>
                      <strong>&nbsp;</strong>
                      <strong>to</strong>
                      <strong>&nbsp;</strong>
                      <strong>$</strong>
                      <strong>&nbsp;</strong>
                      <strong>500,000</strong>
                    </td>
                    <td style={{backgroundColor:"green"}}>
                      <strong>L</strong>
                    </td>
                    <td style={{backgroundColor:"yellow"}}>
                      <strong>M</strong>
                    </td>
                    <td style={{backgroundColor:"red"}}>
                      <strong>H</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Level 3 Fatality/Permanent</strong>
                      <strong>&nbsp;</strong>
                      <strong>Disability/Multiple</strong>
                      <strong>&nbsp;</strong>
                      <strong>Recordable</strong>
                      <strong>&nbsp;</strong>
                      <strong>Injury</strong>
                    </td>
                    <td>
                      <strong>Major</strong>
                      <strong>&nbsp;</strong>
                      <strong>Level</strong>
                      <strong>&nbsp;</strong>
                      <strong>3</strong>
                      <strong>&nbsp;</strong>
                      <strong>incidents</strong>
                    </td>
                    <td>
                      <strong>&gt;</strong>
                      <strong>&nbsp;</strong>
                      <strong>$</strong>
                      <strong>&nbsp;</strong>
                      <strong>500,000</strong>
                    </td>
                    <td style={{backgroundColor:"yellow"}}>
                      <strong >M</strong>
                    </td>
                    <td style={{backgroundColor:"red"}}>
                      <strong>H</strong>
                    </td>
                    <td style={{backgroundColor:"red"}}>
                      <strong>H</strong>
                    </td>
                  </tr>
                  <tr style={{backgroundColor:"grey"}}>
                    <td>
                      <strong>CODE</strong>
                    </td>
                    <td colspan="5">
                      <strong>MEANING</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"green"}}>
                      <strong>L</strong>
                    </td>
                    <td colspan="5">
                      <strong>Control</strong>
                      <strong>&nbsp;</strong>
                      <strong>measures</strong>
                      <strong>&nbsp;</strong>
                      <strong>are</strong>
                      <strong>&nbsp;</strong>
                      <strong>acceptable.</strong>
                      <strong>&nbsp;</strong>
                      <strong>Additional</strong>
                      <strong>&nbsp;</strong>
                      <strong>controls</strong>
                      <strong>&nbsp;</strong>
                      <strong>should</strong>
                      <strong>&nbsp;</strong>
                      <strong>be</strong>
                      <strong>&nbsp;</strong>
                      <strong>considered</strong>
                      <strong>&nbsp;</strong>
                      <strong>if</strong>
                      <strong>&nbsp;</strong>
                      <strong>cost-effective</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"yellow"}}>
                      <strong>M</strong>
                    </td>
                    <td colspan="5">
                      <strong>Additional</strong>
                      <strong>&nbsp;</strong>
                      <strong>control</strong>
                      <strong>&nbsp;</strong>
                      <strong>measures</strong>
                      <strong>&nbsp;</strong>
                      <strong>are</strong>
                      <strong>&nbsp;</strong>
                      <strong>required.</strong>
                      <strong>&nbsp;</strong>
                      <strong>Should</strong>
                      <strong>&nbsp;</strong>
                      <strong>only</strong>
                      <strong>&nbsp;</strong>
                      <strong>proceed</strong>
                      <strong>&nbsp;</strong>
                      <strong>with</strong>
                      <strong>&nbsp;</strong>
                      <strong>appropriate authorization</strong>
                      <strong>&nbsp;</strong>
                      <strong>after</strong>
                      <strong>&nbsp;</strong>
                      <strong>additional</strong>
                      <strong>&nbsp;</strong>
                      <strong>controls</strong>
                      <strong>&nbsp;</strong>
                      <strong>are</strong>
                      <strong>&nbsp;</strong>
                      <strong>implemented.</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"red"}}>
                      <strong>H</strong>
                    </td>
                    <td colspan="5">
                      <strong>Do</strong>
                      <strong>&nbsp;</strong>
                      <strong>not</strong>
                      <strong>&nbsp;</strong>
                      <strong>proceed.</strong>
                      <strong>&nbsp;</strong>
                      <strong>Remaining</strong>
                      <strong>&nbsp;</strong>
                      <strong>risk</strong>
                      <strong>&nbsp;</strong>
                      <strong>is</strong>
                      <strong>&nbsp;</strong>
                      <strong>imminent</strong>
                      <strong>&nbsp;</strong>
                      <strong>or</strong>
                      <strong>&nbsp;</strong>
                      <strong>potential</strong>
                      <strong>&nbsp;</strong>
                      <strong>severity is</strong>
                      <strong>&nbsp;</strong>
                      <strong>unacceptable</strong>
                      <strong>&nbsp;</strong>
                      <strong>with</strong>
                      <strong>&nbsp;</strong>
                      <strong>current</strong>
                      <strong>&nbsp;</strong>
                      <strong>control</strong>
                      <strong>&nbsp;</strong>
                      <strong>measures.</strong>
                      <strong>&nbsp;</strong>
                      <strong>Task</strong>
                      <strong>&nbsp;</strong>
                      <strong>shall</strong>
                      <strong>&nbsp;</strong>
                      <strong>be</strong>
                      <strong>&nbsp;</strong>
                      <strong>redefined</strong>
                      <strong>&nbsp;</strong>
                      <strong>or</strong>
                      <strong>&nbsp;</strong>
                      <strong>further control</strong>
                      <strong>&nbsp;</strong>
                      <strong>measures</strong>
                      <strong>&nbsp;</strong>
                      <strong>shall</strong>
                      <strong>&nbsp;</strong>
                      <strong>be</strong>
                      <strong>&nbsp;</strong>
                      <strong>put</strong>
                      <strong>&nbsp;</strong>
                      <strong>in place</strong>
                      <strong>&nbsp;</strong>
                      <strong>to</strong>
                      <strong>&nbsp;</strong>
                      <strong>reduce</strong>
                      <strong>&nbsp;</strong>
                      <strong>risk.</strong>
                      <strong>&nbsp;</strong>
                      <strong>Controls</strong>
                      <strong>&nbsp;</strong>
                      <strong>shall</strong>
                      <strong>&nbsp;</strong>
                      <strong>be</strong>
                      <strong>&nbsp;</strong>
                      <strong>reassessed</strong>
                      <strong>&nbsp;</strong>
                      <strong>prior to</strong>
                      <strong>&nbsp;</strong>
                      <strong>the</strong>
                      <strong>&nbsp;</strong>
                      <strong>task</strong>
                      <strong>&nbsp;</strong>
                      <strong>commencing</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3>HAZARD REPORTING PROCEDURE</h3>
            <p className="lead">
              To prevent workplace incidents, injuries and illnesses hazards
              must be reported and rectified. The procedure has been developed
              to ensure that all hazards are reported and where required
              elevated to the necessary level to ensure that they are eliminated
              in the most expedient time possible.
            </p>

            <p className="lead">
              All personnel are required to report all hazards immediately and
              to ensure that appropriate measures are implemented immediately to
              prevent an incident, injury or illness. All supervisory personnel
              are to take immediate action to rectify any hazard either
              identified by them or reported to them.
            </p>

            <p className="lead">
              Where the hazard poses an immediate threat to the health and
              safety of any person, then work in the immediate/affected area
              shall cease until such time as it is rectified. Where a hazard is
              not rectified immediately then consultation shall take place
              between the affected employees, ACK Power Technology HES
              Representative and Client HES Representatives to identify an
              appropriate and adequate solution. All hazards must be reduced to
              as low as reasonably practical (ALARP).
            </p>

            <h3>REMEDIAL ACTION</h3>
            <p className="lead">
              The Hierarchy of Control shall be used when deciding upon the
              action to be taken to eliminate or reduce the risk of a hazard.
              The most appropriate form of control is elimination with the least
              effective being PPE. It may often be necessary to combine two or
              more controls to reduce the risk posed by a hazard to an
              acceptable level.
            </p>

            <h3>HAZARD REPORT FORM</h3>
            <p className="lead">
              All hazards are to be documented and submitted to the HES
              Representative for review and close-out of the action taken.
              Hazard Report forms shall not be filed as completed until all
              approved action items are completed, reviewed and signed off by
              the Project HES Representative or delegate. All Hazard Report
              Forms shall be entered in a register for periodical review.
            </p>

            <h3>COMMUNICATION OF REPORTED HAZARD</h3>
            <p className="lead">
              The ACK Power Technology Project Manager shall ensure that all
              Project Management team members discuss any new additions to the
              Hazard Register at the next management team meeting
            </p>

            <h3>INVESTIGATION AND CORRECTIVE ACTION</h3>
            <p className="lead">
              The HES Representative shall implement the requirements of the
              incident notification regulations and the incident reporting and
              investigation procedure for the project. He shall ensure that
              reports are accurate and provide to the Project Manager within the
              time requirements of the procedure. The HES Representative shall
              ensure that the report forms are completed, accurate and fully
              investigated.
            </p>

            <p className="lead">
              Additionally, if required, the HES Representative shall raise an
              Improvement Notification Report (INR) where improvements to
              management system documents are required as a result of audit.
              These corrective actions shall be issued to the Project Manager
              with a statement of actions required; date to be completed and
              date where follow-up will be performed to ensure effectiveness of
              the changes. The Incident Tracking Register shall indicate the
              closing out of the corrective action. Where HES complaints are
              raised by the project personnel the HES Representative shall
              follow up as necessary.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
