
import { Link } from "react-router-dom"
import "./not.css"
export default function NotFound(){
    return(
        <div className="notfound">
            <div style={{textAlign:"center"}}>
            <h1>Page not not found !</h1>
            <Link style={{color:"#307BFF"}} to={"/gallery"}>Visit our picture gallery</Link>
            </div>

        </div>
    )
}