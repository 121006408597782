export default function SafetyAudit() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>SAFETY RECORDS AND AUDITS</h2>
            <p className="lead">
              The following safety records shall be provided.
            </p>

            <br></br>
            <p className="lead">
              Safety audits shall be performed in accordance with the following
              plan:
            </p>

            <div className="table-responsive">
            <table style={{ marginTop: "10px" }} class="table table-striped lead">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>AUDIT</strong>
                      <strong>&nbsp;</strong>
                      <strong>AREA</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>AUDIT</strong>
                      <strong>&nbsp;</strong>
                      <strong>SCOPE</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>AUDITOR</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>AUDIT</strong>
                      <strong>&nbsp;</strong>
                      <strong>SCHEDULE</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Project Establishment</p>
                  </td>
                  <td>
                    <p>Safety Review</p>
                  </td>
                  <td>
                    <p>Project Manager</p>
                  </td>
                  <td>
                    <p align="center">Depending on Project</p>
                    <p align="center">Calendar</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Project Site</p>
                    <ul>
                      <li>Site Yard</li>
                      <li>Storage</li>
                      <li>Amenities</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Induction</li>
                      <li>Roles &amp; Obligations</li>
                      <li>JSEA</li>
                      <li>Toolbox</li>
                      <li>Safety Procedures</li>
                      <li>Safety Equipment</li>
                      <li>Documentation</li>
                      <li>Training</li>
                      <li>Plant</li>
                      <li>Dangerous Goods</li>
                      <li>Housekeeping</li>
                      <li>PPE</li>
                    </ul>
                  </td>
                  <td>
                    <p>HES Representative</p>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <p>Project Compliance</p>
                  </td>
                  <td>
                    <p>Compliance Audit of Project HES</p>
                    <p>Management Plan</p>
                  </td>
                  <td>
                    <p>PL Management Project</p>
                    <p>Manager</p>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
