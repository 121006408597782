export default function Health() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>HEALTH, ENVIRONMENT AND SAFETY MANAGEMENT PLAN</h2>
            <p className="lead">
              ACK Power Technology employees firmly believe a meaningful HES
              Plan for the project depends on the leadership and support from
              the management. This support is demonstrated by having in place
              Health and Safety policy which includes clear statements of the
              objectives and management commitment. ACK Power Technology Works
              employees will ensure this policy is communicated to all personnel
              and implemented throughout the Project
            </p>
            <h3>HEALTH & SAFETY OBJECTIVES</h3>
            <p className="lead">
              ACK Power Technology Works employee‘s vision is to achieve zero
              incident in all operations of this Project. The safety objectives
              of this plan are to be used to raise awareness and establish a
              Health & Safety culture on this Project that strives for
              continuous improvement.
            </p>

            <p className="lead">The following specific objectives apply to this project:</p>
            <ul style={{marginTop:"10px"}} className="lead">
              <li>
                To enlist and maintain support for the health, safety and
                environment objectives of the project at all levels within the
                organization.
              </li>
              <li> To provide and maintain safe systems of work.</li>
              <li>
                To clearly define areas of Health, Safety and Environment
                responsibility.
              </li>
              <li>
                To identify hazards, assess and control risks and implement and
                review Health and Safety procedures.
              </li>
              <li>
                To ensure all project personnel complete the Project specific
                induction.
              </li>
              <li>
                To thoroughly investigate all incidents including near miss
                events to prevent occurrence.
              </li>
              <li>
                To involve all levels of the Project management team and the
                workforce in periodic audits and inspections of the Project
                construction activities.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
