import { useEffect, useState } from "react";
import "./Gal.css";
import "./Gallery.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Gallery } from "react-grid-gallery";
import { ProjectData } from "../../Data";

import Loading from "../../Loading";

export function RenderGal({ id, start, end }) {
  const [listOfImages, setListOfImages] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const projects = ProjectData();

  function getProjectDescription(id) {
    return projects.find((obj) => obj.id === id);
  }
  function findProjectFromSrc(filename) {
    if (filename) {
      if (filename.includes("training")) {
        return { name: "Training Pictures" };
      }
      const pro = projects.find((obj) => filename.includes(obj.id));
      console.log(`${filename} `, pro);
      if (pro) {
        return pro;
      } else {
        return { name: "No project Data" };
      }
    } else {
      return { name: "No filename" };
    }
  }

  useEffect(() => {
    setLoading(true);

    const importAll = async (r) => {
      const promises = r.keys().map(async (fileName) => {
        const image = new Image();
        image.src = r(fileName);

        return new Promise((resolve, reject) => {
          image.onload = () => {
            // const projectData = getProjectDescription(id);
            const pro = findProjectFromSrc(image.src);

            resolve({
              src: r(fileName),
              width: image.naturalWidth,
              height: image.naturalHeight,
              customOverlay: (
                <div className="bg-text">
                  <div>{pro.name}</div>
                </div>
              ),
            });
          };
          image.onerror = (error) => {
            reject(error);
          };
        });
      });
      const images = await Promise.all(promises);
      setLoading(false);
      if (id) {
        const filteredImages = images.filter((image) => image.src.includes(id));
        if (start !== undefined && end !== undefined) {
          const imageRange = filteredImages.slice(start, end);
          setListOfImages(imageRange);
        } else if (start == undefined && end !== undefined) {
          const imageRange = filteredImages.slice(end);
          setListOfImages(imageRange);
        } else {
          setListOfImages(filteredImages);
        }
      } else {
        setListOfImages(images);
      }
    };

    importAll(
      require.context("../../media/all", false, /\.(PNG|png|jpe?g|svg)$/)
    );
  }, [id]);

  const ImageComponent = (props) => {
    return (
      <PhotoView key={props.imageProps.src} src={props.imageProps.src}>
        <img
          thumbnailStyle={{ borderRadius: "5px" }}
          style={{}}
          {...props.imageProps}
        />
      </PhotoView>
    );
  };
  return (
    <div>
      {isLoading && (
        <Loading/>
        // <div className="loading">
        //   <div style={{ textAlign: "center" }}>
        //     <Spinner className="spin" animation="grow" />
        //     <p style={{ marginTop: "30px" }}>Picture loading, please wait...</p>
        //   </div>
        // </div>
      )}
      {listOfImages.length > 0 && !isLoading && (
        <PhotoProvider maskOpacity={0.8}>
          <Gallery
            rowHeight={450}
            thumbnailImageComponent={ImageComponent}
            images={listOfImages}
            enableImageSelection={false}
          />
        </PhotoProvider>
      )}
    </div>
  );
}

export default function MyGal() {
  return (
    <div>
      <section id="portfolio">
        <div class="container">
          <div class="section-header">
            <h2>Photo Gallery of our projects</h2>
            <p className="lead" style={{ width: "80%", maxWidth: "700px" }}>
              Explore our project gallery and see the quality and craftsmanship
              of our work. From commercial buildings to residential properties,
              we take pride in delivering exceptional results for every project
              we undertake.
            </p>
            <RenderGal />
          </div>
        </div>
      </section>
    </div>
  );
}
