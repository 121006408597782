import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProjectData, ResourcesList } from "../../Data";
import "./Nav.css"
import { navdata } from "../../App";
import { projects } from "../../Data"
import Banner from "./banner.jpg"
import Logo from "./logo.jpg"

export default function Navigation() {

    const projects = ProjectData();
    return (
        <div className="headnav" >
            <div className="banner">
                <div className="outer">
                    <div className="inner">
                        {/* <div class="icon-container"> */}
                            <img src={Banner}  alt="icon"/>
                        {/* </div> */}

                        {/* <img src={Banner} alt="banner" /> */}
                    </div>
                </div>
            </div>
            <div className="nav">
                <div className="logoArea">
                    <Link to={"/"}>
                        <div className="logo">
                            <img
                                alt=""
                                src={Logo}
                                width="30"
                                height="30"
                            />
                            <b style={{ color: "#140568" }}>ACKPower</b>
                        </div>
                    </Link>
                </div>


                <div className="dropdown navItem">
                    <a href="javascript:void(0)" className="dropbtn">About us</a>
                    <div className="dropdown-content">
                        {navdata[0].nav.map(item => (
                            <Link to={item.path}>{item.name}</Link>
                        ))}
                    </div>
                </div>

                <div className="dropdown navItem">
                    <a href="javascript:void(0)" className="dropbtn">Projects</a>
                    <div className="dropdown-content">

                        {projects.reverse().map((item, i) => (
                            <Link to={"/projects/" + i}>
                                {item.name}
                            </Link>

                        ))}
                    </div>
                </div>

                <div className="dropdown navItem">
                    <a href="javascript:void(0)" className="dropbtn">Resources</a>
                    <div className="dropdown-content">
                        {navdata[1].nav.map(item => (
                            <Link to={item.path}>{item.name}</Link>
                        ))}
                    </div>
                </div>

                <div className="dropdown navItem">
                    <a href="javascript:void(0)" className="dropbtn">HSE</a>
                    <div className="dropdown-content">
                        {navdata[2].nav.map(item => (
                            <Link to={item.path}>{item.name}</Link>
                        ))}
                    </div>
                </div>

                <div className="navItem only">
                    <Link to="/capabilities">Our Capabilities</Link>
                </div>

                <div className="navItem only">
                    <Link to="/gallery">Gallery</Link>
                </div>

                <div className="navItem only end">
                    <Link to="/contact">Contact</Link>
                </div>

            </div>

        </div>
    )
}