import "./Footer.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { infoData } from "../../Data";
import { Link } from "react-router-dom";



export default function Footer() {
    const info = infoData();
    return (
        <Container className="footer" fluid>
            <Row className="topThingy">
                <Col className="overview" lg={6} md={6} sm={12} >
                    <p><b>Overview</b></p>
                    <p>
                        <Link
                            style={{
                                color: "white",

                            }}
                            to={"/intro"}
                        >
                            Introduction
                        </Link>
                    </p>
                    <p>
                        <Link
                            style={{
                                color: "white",

                            }}
                            to={"/ceonotes"}
                        >
                            CEO's notes
                        </Link>
                    </p>
                    <p>
                        <Link
                            style={{
                                color: "white",
                            }}
                            to={"/awards"}
                        >
                            Our Awards And Achievements
                        </Link>
                    </p>
                    <p>
                        <Link
                            style={{
                                color: "white",

                            }}
                            to={"/hseInto"}
                        >
                            Health Environment and Safety Management Plan
                        </Link>
                    </p>

                </Col>

                <Col lg={6} md={6} sm={12}>
                    <p><b>Contact information</b></p>
                    <p>Address: {info.address}</p>
                    <Link
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                        to={"https://goo.gl/maps/cB1Fqjhrva4534Hq5"}
                    >
                        Office location on google map
                    </Link>
                    <br></br>
                    <br></br>

                    <p style={{ display: "inline", marginRight: "10px" }}>Phone:</p>
                    {info.tel.map((num) => (
                        <p style={{ display: "inline", marginRight: "10px" }}>{num}</p>
                    ))}
                    <p>
                        Email: <b>{info.mail}</b>
                    </p>


                    <Link
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                        to={"/contact"}
                    >
                        Contact us
                    </Link>
                </Col>
            </Row>


            <Row className="bottom">
                <Col>
                    <p>Copyright © {new Date().getFullYear()} Ack power technology</p>
                    <p style={{color:"#d7d6d6",fontWeight:"400"}}>
                        Developed by{" "}
                        <a className="footerLink" href="https://echo-saurav.github.io/">
                            echo-saurav
                        </a>
                    </p>
                </Col>

            </Row>

        </Container>
    );
}
