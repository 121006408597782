import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Client.css";
import { Col, Image, Row, Container, Button } from "react-bootstrap";

export default function ClientSlide() {
  const [listOfImages, setListOfImages] = useState([]);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    setListOfImages(
      importAll(
        require.context("../../media/clients", false, /\.(PNG|png|jpe?g|svg)$/)
      )
    );
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
  };
  const new_set = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <Container className="client">
      <Row className="justify-content-md-center">
        <Col lg={8} md={8} sm={12} className="client">
          <h1>Our clients</h1>
          <i>Trusted by Leading Companies Worldwide</i>
          <p className="lead">
            Our company delivers effective and innovative solutions for unique
            business needs. Our team of experts provide proven results with our
            trusted clients. Let us take your business to the next level.
          </p>

          <div>
            <Slider {...new_set}>
              {listOfImages.map((url) => (
                <div>
                <div className="imageContainer">
                  <img src={url} className="clientImage" />
                </div>
                </div>

              ))}
            </Slider>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
