import "./Project.css"

import { useParams } from "react-router-dom";
import { ProjectData } from "../../Data";
import { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { RenderGal } from "../gallery/Gallery";

function Exist({ data }) {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>{data.name}</h2>
            <p>{data.desc}</p>
            <RenderGal id={data.id} />
          </div>
        </div>
      </section>
    </div>
  );
}

function NotExist() {
  return (
    <div>
      <h1>Project not found , please check your link</h1>
    </div>
  );
}

export default function Projects() {
  const { id } = useParams();
  const projectsList = ProjectData();
  const project = projectsList.reverse()[id];

  return (
    <section id="services">
      <div class="container">
        <div class="section-header">
          {/* {project ? <Exist data={project} /> : <NotExist />} */}
          {project ? (
            <section id="services">
              <div class="container">
                <div class="section-header">
                  <h2>{project.name}</h2>
                  <p className="lead" style={{marginBottom:"10px"}} >{project.desc}</p>
                  <RenderGal id={project.id} />
                </div>
              </div>
            </section>
          ) : (
            <section id="services">
              <div class="container">
                <div class="section-header">
                  <h2>Project don't exist</h2>
                  <p>Checkout other projects</p>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </section>
  );
}
