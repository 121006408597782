export default function JobSafety() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>JOB SAFETY AND ENVIRONMENTAL ANALYSIS (JSEA)</h2>
            <p className="lead">
              The HES Representative in conjunction with the Project Management
              Team shall develop a preliminary Job Safety and Environmental
              Analysis (JSEA) for the project work activities.
              <p className="lead">

                Having performed the JSEA and assessed the risk, control
                measures shall be determined. Where possible the risk should be
                minimized and/or eliminated through changes to the work process.
                Where this is not practicable other forms of control may be
                required. SEA will be reviewed and addressed at the toolbox
                meeting with work crew to adequately manage the hazards and
                risks associated with the project.{" "}
              </p>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
