import { RenderGal } from "../gallery/Gallery";

export default function Trainning() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>TRAINING</h2>
            <RenderGal id="training" start={0} end={4}/>
            <p className="lead">
              ACK Power Technology Project Management team has the
              responsibility to ensure its management, supervisors and employees
              have the necessary skills and knowledge and are competent to
              advise and enforce compliance of this{" "}
              <b>Project HES Management Plan (PHSEMP).</b>
            </p>

            <p className="lead">
              In understanding the requirements for employee selection,
              competency and training it is important to recognize that almost
              all personnel involved in construction, operation and maintenance
              can create a safety hazard if they are not competent, qualified
              and suitably trained to carry out their role.
            </p>
            <p className="lead">
              ACK Power Technology employees engaged to work, as part of the
              Project, will attend the project specific safety induction before
              commencing on site work. This induction will be reviewed and
              updated to suit the changing project conditions. The HES
              Representative shall develop and implement induction requirements
              for project staff and personnel. The HES Representative shall
              develop a register of inducted personnel. It is the project
              management's responsibility to ensure that personnel do not
              commence field operations until inducted.
            </p>

            <p className="lead">
              All personnel shall be required to complete an Induction
              Attendance form prior to the induction. All licenses and
              certificates of competency held must be recorded on the Induction
              Attendance form.
            </p>

            <p className="lead">
              An integral part of the induction process will be the explanation
              of the following:
            </p>
            <p style={{marginTop:"10px"}} className="lead">
              <ul>
                <li>Objectives of the project.</li>
                <li>Project safety objectives.</li>
                <li>Emergency Response Procedures.</li>
                <li>Site-specific hazards.</li>
                <li>Site safety rules.</li>
                <li>Smoking policy.</li>
                <li>Alcohol and other Drugs Policy.</li>
                <li>Hazard Identification and reporting.</li>
                <li>JSEA preparation.</li>
                <li>Toolbox meetings.</li>
                <li>Incident reporting and investigation.</li>
                <li>Site orientation.</li>
              </ul>
            </p>
            <p className="lead">
              Following the main induction all personnel are to have a
              familiarization tour of the Project accommodation and the
              following is to be pointed out
            </p>

            <ul style={{marginTop:"10px"}} className="lead">
              <li>
                Emergency Procedure and facilities e.g. First Aid, Fire
                Extinguishers, Assembly Points, etc.
              </li>
              <li>Hours of Work.</li>
              <li>First Aid Representative.</li>
              <li>Personnel in crew.</li>
              <li>Location of Amenities.</li>
            </ul>

            <p className="lead">
              The HES Representative shall implement the requirements of the
              Project Line Safety Training Proced
            </p>
            <RenderGal id="training" end={4}/>
          </div>
        </div>
      </section>
    </div>
  );
}
