import "./Resources.css"

import { toolsList } from "../../Data";
import ceo from "../../media/peoples/ceo.jpeg";
import one from "./images/1.png";
import two from "../../media/peoples/ceo.jpeg";
import three from "./images/3.png";
import four from "./images/4.png";
import five from "./images/5.png";
import six from "./images/6.png";
import seven from "./images/7.png";
import eight from "./images/8.png";
import nine from "./images/9.png";
import ten from "./images/10.png";
import eleven from "./images/11.png";
import twelve from "./images/12.png";
import thirteen from "./images/13.png";
import fourteen from "./images/14.png";
import fifteen from "./images/15.png";
import sixteen from "./images/16.png";
import seventeen from "./images/17.png";
import eighteen from "./images/18.png";
import nineteen from "./images/19.png";
import twenty from "./images/20.png";
import twentyOne from "./images/21.png";
import twentyTwo from "./images/22.png";
import twentyThree from "./images/23.png";
import twentyFour from "./images/24.png";
import twentyFive from "./images/25.png";
import twentySix from "./images/26.png";
import twentySeven from "./images/27.png";

const emp = [
    // {
    //   image: one,
    //   name: "AKTER HOSSAIN",
    //   des: "Honorable Consultant",
    //   quali: "M.B.A. (HRM)",
    //   exp: "15 Years on Business Development / Entrepreneurship",
    // },
    {
      image: two,
      name: "Engr. Nurul Islam",
      des: "CEO",
      quali: "BEng. in Mechanical Engineering",
      exp: "20 Years on Mechanical Construction & Consultancy (Home & Abroad)",
    },
    {
      image: three,
      name: "Masudur Rahman",
      des: "General Manager",
      quali: "M.B.A.",
      exp: "15 Years on Business Development/Entrepreneurship",
    },
    {
      image: four,
      name: "Md.Russel Hossain",
      des: "Legal Advisor",
      quali: "MSc in Chemistry",
      exp: "10 Years of Experience on Business Development",
    },
    {
      image: five,
      name: "Mominul Hoque",
      des: "Admin Manager",
      quali: "MSc. in Physics",
      exp: "08 Years of Experience",
    },
    {
      image: six,
      name: "Md.Emam Hasan",
      des: "EHS Manager",
      quali: "BSc. in Electrical Engineering",
      exp: "10 Years of Experience",
    },
    {
      image: seven,
      name: "RAFAT ARIYAN",
      des: "Accounts Manager",
      quali: "B.B.A in Accounting",
      exp: "5 Years of Experience",
    },
    {
      image: eight,
      name: "Motaher Hossaain",
      des: "Project Manager",
      quali: "Diploma in Mechanical Engineering",
      exp: "12 Years of Experience (Home & Abroad)",
    },
    {
      image: nine,
      name: "Robiul Mondol",
      des: "Civil Manager.",
      quali: "BSc. in Civil Engineering",
      exp: "12 Years of Experience",
    },
    {
      image: ten,
      name: "Moynul Islam",
      des: "Electrical Engineer",
      quali: "BSc. in Electrical Engineering",
      exp: "6 Years of Experience",
    },
    {
      image: eleven,
      name: "Md.Shahin",
      des: "Mechanical Engineer",
      quali: "BSc. in Mechanical Engineering",
      exp: "10 Years of Experience",
    },
    {
      image: twelve,
      name: "Ananta Dey",
      des: "Mechanical Engineer",
      quali: "BSc. in Mechanical Engineering",
      exp: "3 Years of Experience",
    },
    {
      image: thirteen,
      name: "Shariar Sumon",
      des: "Civil Engineer",
      quali: "BSc. in Civil Engineering",
      exp: "4 Years of Experience",
    },
    {
      image: fourteen,
      name: "Mito Akhter",
      des: "Interpreter (Chinese)",
      quali: "BSc. in Civil Engineering(China)",
      exp: "4 Years of Experience (Home & Abroad)",
    },
    {
      image: fifteen,
      name: "Md. Hanif Sharker",
      des: "QC Supervisor",
      quali: "Diploma in Mechanical Technology",
      exp: "8 Years of Experience",
    },
    {
      image: sixteen,
      name: "Md. Shahid",
      des: "Structural Supervisor Incharge",
      quali: "Highly Skilled Steel and Pipe Fabricator",
      exp: "15 Years of Experience (Home & Abroad)",
    },
    {
      image: seventeen,
      name: "Mahabub Alam",
      des: "Steel Structural Supervisor",
      quali: "Diploma in Mechanical Engineering",
      exp: "10 Years of Experience",
    },
    {
      image: eighteen,
      name: "Abu Anas",
      des: "Steel Structural Supervisor",
      quali: "Diploma in Mechanical Engineering",
      exp: "12 Years of Experience (Home & Abroad)",
    },
    {
      image: nineteen,
      name: "Masum Mollah",
      des: "Tank Supervisor",
      quali: "Highly Skilled Steel and Plate Fabricator",
      exp: "12 Years of Experience",
    },
    {
      image: twenty,
      name: "Waliur Rahman",
      des: "Tank Supervisor",
      quali: "Highly Skilled Plate Fabricator",
      exp: "9 Years of Experience",
    },
    {
      image: twentyOne,
      name: "Shahnewaz",
      des: "Piping Supervisor",
      quali: "Diploma in Mechanical Engineering",
      exp: "11 Years of Experience (Home & Abroad)",
    },
    {
      image: twentyTwo,
      name: "Md. Shohag",
      des: "Lifting Supervisor",
      quali: "B.B.A in Accounting",
      exp: "8 Years of Experience (Home & Abroad)",
    },
    {
      image: twentyThree,
      name: "Jiaur Rahman",
      des: "HSE In Charge",
      quali: "BSc. in Health Science",
      exp: "6 Years of Experience",
    },
    {
      image: twentyFour,
      name: "Mominul Islam",
      des: "HSE Supervisor",
      quali: "BSc in Textile Engineering",
      exp: "5 Years of Experience (Home & Abroad)",
    },
    {
      image: twentyFive,
      name: "Yeaminul Alam",
      des: "HSE Officer",
      quali: "B.B.A (Ongoing)",
      exp: "3 Years of Experience",
    },
    {
      image: twentySix,
      name: "Himel",
      des: "HSE Officer",
      quali: "B.B.A (Ongoing)",
      exp: "4 Years of Experience",
    },
    // {
    //   image: twentySeven,
    //   name: "Mahfuzur Rahman",
    //   des: "Structural Foreman",
    //   quali: "Highly Skilled Steel Fabricator",
    //   exp: "8 Years of Experience",
    // },
  ];
  

export default function TechnicalEmployee(){
    const tools = toolsList();
    console.log(tools.length);
  
    return (
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>Technical Employee</h2>
            <p className="lead">
              The company is supported by skilled Naval Architects, Mechanical
              Engineers, Welders, Fitters, Riggers, Electricians, Mechanics and
              other related Technicians with long working experiences in local and
              foreign shipyards/companies.
            </p>
            <h3>Key Personnel</h3>
            <div className="table-responsive">
  
  
            <table  className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Photo</th>
                  <th scope="col">Name</th>
                  <th scope="col">Qualification</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Experience (Years)</th>
                </tr>
              </thead>
              <tbody>
                {emp.map((item, index) => (
                  <tr>
                    <th scope="row">
                      <img src={item.image} style={{ width: "100px" }} />
                    </th>
                    <td>{item.name}</td>
                    <td>{item.quali}</td>
                    <td>{item.des}</td>
                    <td>{item.exp}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </section>
    );
  
}