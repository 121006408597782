import "./AboutUs.css"
import { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

export default function Awards(){
    const [listOfImages, setListOfImages] = useState([]);
    useEffect(() => {
      const importAll = (r) => r.keys().map(r);
      setListOfImages(
        importAll(
          require.context("../../media/awards", false, /\.(PNG|png|jpe?g|svg)$/)
        )
      );
    }, []);
  
    return (
      <div>
        <section id="services">
          <div class="container">
            <div class="section-header">
              <h2>Awards and Achievements</h2>
              <p className="lead">
                We are proud to showcase our awards and achievements, which are a
                testament to our hard work and dedication in delivering
                exceptional services. These awards recognize our commitment to
                quality, safety, and customer satisfaction. They also reflect our
                team's expertise and ability to take on challenging projects. We
                are honored to have received these accolades and will continue to
                strive for excellence in everything we do.
              </p>
              <div className="certificateContainer">
                <PhotoProvider maskOpacity={0.8}>
                  {listOfImages.map((item, index) => (
                    <PhotoView key={index} src={item}>
                      <img className="certificate" src={item} />
                    </PhotoView>
                  ))}
                </PhotoProvider>
              </div>
  
            </div>
          </div>
        </section>
      </div>
    );
  
}