import "./Resources.css"
import { toolsList } from "../../Data";


export default function EquipmentAndTools() {
    const tools = toolsList();
    console.log(tools.length);

    return (
        <section id="services">
            <div class="container">
                <div class="section-header">
                    <h2>EquipmentAndTools</h2>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">No</th>
                                    <th scope="col">Instrument Name</th>
                                    <th scope="col">Capacity</th>
                                    <th scope="col">Qt'y</th>
                                    <th scope="col">Unit</th>
                                    <th scope="col">Manufacturer</th>
                                    <th scope="col">Age/year</th>
                                    <th scope="col">Condition</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tools.map((item, index) => (
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.capacity}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.unit}</td>
                                        <td>{item.manuf}</td>
                                        <td>{item.age}</td>
                                        <td>{item.condi}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );

}