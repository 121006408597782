// 1. Power Plant
// 2. Oil and Gas Plant
// 3. Fertilizer Industries,
// 4. Chemical Plant
// 5. Cement Factories and Mixing Plant
// 6.  Sugar Industries
// 7. Water Treatment Plant
// 8. Land Development & Supply works etc.
// Our Objectives:
// HRSG, STG, GTG, Piping, Oil Tank, Steel Structure and Conveyor System, Fabrication, Erection, Insulation, Sandblasting & Painting, HVAC, DUCTING WORKS, Were House Solution,  Electrical                    Engineering and Civil Engineering works Projects.

import { IndustrialEngineeringData } from "../../Data";
import "./capabilities.css";

export default function OurCap() {
  const data = IndustrialEngineeringData();
  console.log("data", data);
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>Our Capabilities</h2>
            <h3>Project Solutions:</h3>
            <p className="lead">
              <ul>
                {data.map((item, i) => (
                  <li>{item.title}</li>
                ))}
              </ul>
            </p>
            <h3>Our Objectives: </h3>
            <p className="lead">
              HRSG, STG, GTG, Piping, Oil Tank, Steel Structure and Conveyor
              System, Fabrication, Erection, Insulation, Sandblasting &
              Painting, HVAC, DUCTING WORKS, Were House Solution, Electrical
              Engineering and Civil Engineering works Projects.
            </p>
            <h3>CREDIBLE SERVICES</h3>
            <p className="lead">
              Successful Water Treatment, Power Plant, Oil Refinery, Chemical,
              Fertilizer, Power Plant, Substation, steel structure tower, Repair
              & Maintenance services Cement Factory, Electrical and Civil
              Engineering Works ,, etc.
            </p>
            <p className="lead">
              Fabrication of Steel Structure, Piping, Mechanical & Electrical,
              drawing & design,Engineering and Productive Process. Engineering.
              Supply global standard Equipment for assistance of growing and
              existing Industries. Successful Track record with Good will from
              our valuable Customers. High experienced management and field
              project operation by professionally & technically experts.
              Employees are motivated for their efficient and effective working
              performance. Industrial maintenance for production. Our policy to
              protect environment, Quality Assurance, and safety measure working
              Conduit.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
