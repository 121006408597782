export default function OrganizationAndResources() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2> ORGANIZATION AND RESOURCES AND DEVELOPMENTS</h2>
            <h3>HEALTH AND SAFETY PERSONNEL</h3>
            <p className="lead">
              ACK Power Technology will engage suitably qualified and
              experienced personnel to fill the following positions to ensure
              that health and safety issues are prevented and/or managed on the
              project: HES Representative HES Supervisor(s), and Paramedic In
              addition, suitably candidates will be encouraged from the
              workforce to be trained as First Aiders to compliment the growing
              ranks of already qualified First Aiders within the ACK Power
              Technology pool of personnel and workforce.
            </p>
            <p className="lead">
              A HES Budget will be developed to ensure that both the corporate
              and HES objectives of the HES Management System requirements on
              the project can be achieved. The following comprise cost
              categories around which the budget for health and safety will be
              allocated on the project:
            </p>
            <h3>Health, Environment and Safety Budget</h3>
            <ul style={{marginTop:"10px"}} className="lead">
              <li>HES Personnel.</li>
              <li>Risk Management Activities.</li>
              <li>Health and Safety Communications.</li>
              <li>Meetings Training (includes Inductions)</li>
              <li>Auditing.</li>
              <li>
                Safety Improvement Programs (includes Safety Behavior Program,
                Transport & Travel Management).
              </li>
              <li>Personal Protective Equipment.</li>
              <li>First Aid.</li>
              <li>Fire / Emergency.</li>
              <li>Equipment Equipment & Plant Safety Program.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
