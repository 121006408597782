export function ProjectData() {
    const projects = [
      {
        name: "225 MW Combine Gas Turbine Power Station",
        desc: "Pipe Line, Boiler, Structure Etc, Sikolbaha-225 MW CCPP Project, Electrical Works",
        id:"225mwCombine"
      },
      
      {
        name: "200 MW HFO Power Plant Jamalpur Mymensing",
        desc: "Piping Structure, By Pass Stack Etc",
        id:"200MWHFO"
      },
      {
        name: "350 MW HFO Power Plant Anowara Chatagonj",
        desc: "Pipe Line, Boiler, Structure Etc",
        id:"350MWHFO"
      },
      {
        name: "BM ENERGY LPG GAS",
        desc: "BM ENERGY LPG GAS",
        id:"BM_ENERGY_LPG_GAS"
      },
      {
        name: "Bibiyana-III 400 MW CCPP Project",
        desc: "Electrical Works",
        id:"400MW"
      },
      // {
      //   name: "Sikolbaha-225 MW CCPP Project",
      //   desc: "Electrical Works",
      //   path:"../../media/projects/"
      // },
      {
        name: "SUMMIT-II 589 MW CCPP Project Meghnaghat, Narayanganj",
        desc: "China Energy Engineering Group Northeast No.1 Electric Power Construction Co.Ltd",
        id:"589MW"
      },
      {
        name: "BIFPCL, 620x2 MW Maitree STPP,Rampal, Bagherhat, Khulna",
        desc: "Power Mech Projects Ltd",
        id:"620X2MW"
      },
      {
        name: "SAMSUNG 718 MW CCPP Project Meghnaghat, Narayanganj",
        desc: "Union Merchentile Ltd (UML)",
        id:"SAMSUNG"
      },
      {
        name: "Ghorasal Palash Urea Fertilizer Project [GPUFP]",
        desc: "China National Chemical Engineering and Construction Corporation Seven Co. Ltd.",
        id:"GHORASAL",
        isOnGoing:true
      },
    ];
  
    return projects;
  }
  
  export function projeect2(){
    const projects = [
      {
        name: "200 MW HFO Power Plant Jamalpur Mymensing",
        desc: "Piping Structure, By Pass Stack Etc",
      },
      
      {
        name: "Ghorasal Palash Urea Fertilizer Project [ GPUFP ]  ",
        desc: "China National Chemical Engineering and Construction Corporation Seven Co. Ltd.",
        isOnGoing:true
      },
    ];
  
    return projects;
  }
  
  export function infoData() {
    const info = {
      tel: ["+88 01731008186", "+88 01999351076"],
      mail: "ackpowertec@gmail.com",
      year: "2015",
      ownerTitle: "Engr. Nurul Islam, (Proprietor)",
      address:
        "Sonargaon Mega Complex, 4th Floor, 96 - Kanchpur Bridege, Narayanganj. Dhaka",
    };
    return info;
  }
  
  export function toolsList() {
    const tools = [
      {
        name: "Mobile Crane",
        capacity: "50 Ton",
        qty: "1",
        unit: "1",
        manuf: "Japan",
        age: "10",
        condi: "Good",
      },
      {
        name: "Mobile Crane",
        capacity: "25 Ton",
        qty: "2",
        unit: "2",
        manuf: "Japan",
        age: "10",
        condi: "Good",
      },
      {
        name: "Dump Truck",
        capacity: "16 Ton",
        qty: "2",
        unit: "2",
        manuf: "Japan",
        age: "5",
        condi: "Good",
      },
      {
        name: "Tailor",
        capacity: "40 Feet Long",
        qty: "1",
        unit: "2",
        manuf: "Japan",
        age: "5",
        condi: "Good",
      },
      {
        name: "Hydra",
        capacity: "20 Ton",
        qty: "1",
        unit: "1",
        manuf: "Japan",
        age: "5",
        condi: "Good",
      },
      {
        name: "Pay loader",
        capacity: "5 Ton",
        qty: "1",
        unit: "1",
        manuf: "Japan",
        age: "5",
        condi: "Good",
      },
      {
        name: "Excavator",
        capacity: "Size - 9",
        qty: "1",
        unit: "1",
        manuf: "Japan",
        age: "5",
        condi: "Good",
      },
      {
        name: "Fork Lift",
        capacity: "5 Ton",
        qty: "1",
        unit: "Sets",
        manuf: "Japan",
        age: "5",
        condi: "Good",
      },
      {
        name: "Plate Band Machine",
        capacity: "10 - 20 Mm",
        qty: "1",
        unit: "Set",
        manuf: "Bangladesh",
        age: "3",
        condi: "Good",
      },
      {
        name: "Square Duct Machine",
        capacity: "Fukk Set",
        qty: "1",
        unit: "Sets",
        manuf: "China",
        age: "10",
        condi: "Good",
      },
      {
        name: "Hydro Test Pump",
        capacity: "-",
        qty: "1",
        unit: "Sets",
        manuf: "Shipping",
        age: "4",
        condi: "Good",
      },
      {
        name: "Spray Painting M/C",
        capacity: "140 Psi",
        qty: "1",
        unit: "Sets",
        manuf: "Shipping",
        age: "5",
        condi: "Good",
      },
      {
        name: "Sandblasting Set",
        capacity: "-",
        qty: "1",
        unit: "Sets",
        manuf: "Shipping",
        age: "5",
        condi: "Good",
      },
      {
        name: "Air Compressor",
        capacity: "10 Psi",
        qty: "1",
        unit: "Sets",
        manuf: "Germany",
        age: "9",
        condi: "Good",
      },
      {
        name: "Auto Cutting M/C",
        capacity: "-",
        qty: "1",
        unit: "Set",
        manuf: "China",
        age: "9",
        condi: "Good",
      },
      {
        name: "TIG Welding machine",
        capacity: "-",
        qty: "150",
        unit: "pcs",
        manuf: "Japan",
        age: "2",
        condi: "Good",
      },
      {
        name: "Grinding machine",
        capacity: "7 inch",
        qty: "200",
        unit: "pcs",
        manuf: "Japan",
        age: "2",
        condi: "Good",
      },
      {
        name: "Grinding machine",
        capacity: "4 inch",
        qty: "200",
        unit: "pcs",
        manuf: "Japan",
        age: "2",
        condi: "Good",
      },
      
      {
        name: "MIG Welding machine",
        capacity: "-",
        qty: "5",
        unit: "pcs",
        manuf: "China",
        age: "2",
        condi: "Good",
      },
      {
        name: "Plasma cutter",
        capacity: "-",
        qty: "4",
        unit: "pcs",
        manuf: "Japan",
        age: "3",
        condi: "Good",
      },
  
      {
        name: "Torque Range",
        capacity: "200-1000",
        qty: "4",
        unit: "pcs",
        manuf: "Japan",
        age: "3",
        condi: "Good",
      },
  
      {
        name: "Torque Range",
        capacity: "1000-2000",
        qty: "4",
        unit: "pcs",
        manuf: "China",
        age: "3",
        condi: "Good",
      },
  
  
      {
        name: "Torque Range",
        capacity: "1500-3000",
        qty: "3",
        unit: "pcs",
        manuf: "China",
        age: "3",
        condi: "Good",
      },
  
      {
        name: "Impact torque Range",
        capacity: "200-1000",
        qty: "10",
        unit: "pcs",
        manuf: "Japan",
        age: "6",
        condi: "Good",
      },
      {
        name: "Impact torque Range",
        capacity: "500-1500",
        qty: "10",
        unit: "pcs",
        manuf: "Japan",
        age: "3",
        condi: "Good",
      },
      {
        name: "Vertical surveyor machine",
        capacity: "-",
        qty: "4",
        unit: "pcs",
        manuf: "China",
        age: "3",
        condi: "Good",
      },
  
      {
        name: "Chain block",
        capacity: "20 ton",
        qty: "20",
        unit: "pcs",
        manuf: "Japan",
        age: "3",
        condi: "Good",
      },
      {
        name: "Chain block",
        capacity: "10 ton",
        qty: "50",
        unit: "pcs",
        manuf: "Japan",
        age: "3",
        condi: "Good",
      },
      {
        name: "Chain block",
        capacity: "5 ton",
        qty: "50",
        unit: "pcs",
        manuf: "Japan",
        age: "3",
        condi: "Good",
      },
      {
        name: "Chain block",
        capacity: "3 ton",
        qty: "30",
        unit: "pcs",
        manuf: "China",
        age: "3",
        condi: "Good",
      },
      {
        name: "Chain block",
        capacity: "2 ton",
        qty: "30",
        unit: "pcs",
        manuf: "China",
        age: "3",
        condi: "Good",
      },
      
      {
        name: "Chain block",
        capacity: "1.5 ton",
        qty: "20",
        unit: "pcs",
        manuf: "Japan",
        age: "2",
        condi: "Good",
      },
      {
        name: "Liber block",
        capacity: "3 ton",
        qty: "10",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Liber block",
        capacity: "1.5 ton",
        qty: "8",
        unit: "pcs",
        manuf: "China",
        age: "3",
        condi: "Good",
      },
      {
        name: "Liber block",
        capacity: "1 ton",
        qty: "5",
        unit: "pcs",
        manuf: "China",
        age: "4",
        condi: "Good",
      },
      {
        name: "Diesel Generator",
        capacity: "50 KW - 100 KW",
        qty: "2",
        unit: "pcs",
        manuf: "China",
        age: "4",
        condi: "Good",
      },
      {
        name: "Forklift",
        capacity: "5 ton",
        qty: "1",
        unit: "pcs",
        manuf: "China",
        age: "4",
        condi: "Good",
      },
      {
        name: "Drill Machine",
        capacity: "10 mm - 52 mm",
        qty: "16",
        unit: "pcs",
        manuf: "China",
        age: "1",
        condi: "",
      },
      {
        name: "Pipe Wrenches",
        capacity: "12 inch",
        qty: "15",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Pipe Wrenches",
        capacity: "18 inch",
        qty: "15",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Pipe Wrenches",
        capacity: "24 inch",
        qty: "10",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Pipe Wrenches",
        capacity: "36 inch",
        qty: "5",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Pipe Wrenches",
        capacity: "42 inch",
        qty: "2",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Pipe Bending machine",
        capacity: "1 inch - 1.5 inch",
        qty: "2",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Hydraulic Jack",
        capacity: "10 ton",
        qty: "10",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Hydraulic Jack",
        capacity: "15 ton",
        qty: "12",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Hydraulic Jack",
        capacity: "30 ton",
        qty: "8",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Hydraulic Jack",
        capacity: "50 ton",
        qty: "6",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      {
        name: "Hydraulic Jack",
        capacity: "80 ton",
        qty: "4",
        unit: "pcs",
        manuf: "Japan",
        age: "1",
        condi: "Good",
      },
      
      
    ];
  
    return tools;
  }
  
  
  
  export function ResourcesList() {
    const resource = [
      { title: "CEO", href: "/ceo" },
      { title: "Technical Employees", href: "/employees" },
      { title: "Consultants", href: "/consultants" },
      { title: "ACK Steel Factory", href: "/fabricationyard" },
      { title: "Equipments and Tools", href: "/tools" }
    ];
  
    return resource;
  }
  export function CapabilitiesList(){
    const capabilities=[
      { title: "Industrial Engineering", href: "/IndustrialEngineering" },
      { title: "Ship Building and Repair", href: "/ceonotes" },
      { title: "Electrical Engineering", href: "/ceonotes" },
      { title: "Consultancy", href: "/ceonotes" },
      { title: "IT and other Engineering Services", href: "/ceonotes" }
    ]
    return capabilities;
  }
  
  export function IndustrialEngineeringData(){
    const IndustrialEngineeringData=[
      {title:"Power Plant "},
      {title:"Oil and Gas Plant "},
      {title:"Fertilizer Industries"},
      {title:"Chemical Plant "},
      {title:"Cement Factories and Mixing Plant"},
      {title:"Sugar Industries"},
      {title:"Water Treatment Plant"},
      {title:"Land Development & Supply works etc"},
  
    ]
    return IndustrialEngineeringData
  }
  
  
  export function PageData(){
    const projectData={
      demo:[
        {type:"h2",value:"This is the title"},
        {type:"p",value:"this is paragraph"},
        {type:"p",value:"this is paragraph2"},
        {type:"h2",value:"This is the title"},
        {type:"img",value:"../../media/img"},
        {type:"h2",value:"This is the title"},
        {type:"b",value:"this is paragraph"},
        {type:"p",value:"this is paragraph2"},
        {type:"h2",value:"This is the title"},
      ]
    }
    return projectData
  }
  
  