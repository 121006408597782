export default function Introduction() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>Introduction</h2>
            <p className="lead">
              ACK POWER TECHNOLOGY is a Company founded in 2015 that is engaged in all
              types of Mechanical Engineering works - especially HRSG, STG, GTG,
              Piping, Oil Tank, Steel Structure and Conveyor System,
              Fabrication, Erection, Insulation, Sandblasting & Painting, HVAC,
              DUCTING WORKS, Wire House Solution, Electrical Engineering and
              Civil Engineering works Projects etc.
            </p>
            <p className="lead">
              The Company was founded by Engr. Nurul Islam (Mechanical Diploma
              From ITE WSQ Singapore ) who served long 25 years, out of which
              about 5 years he served as Project Manager at Oil and Gas, Brunei.
            </p>
            <p className="lead">
              After Completing from oil and gas, brunei Engr. Nurul Islam was
              undertaking Mechanical Contracting Works (up to the end of 2014)
              under the banner of a Company named Techno Associate (as Project
              Manager).
            </p>

          </div>
        </div>
      </section>
    </div>
  );
}
