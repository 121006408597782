import "./Home.css";
// import HomeImage from "./HomeImage.png";
import HomeImage from "./Home.webp";

import Team from "./team.jpeg";
import ClientSlide from "./Client";
import { Col, Image, Row, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProjectList from "./ProjectList";

export default function Home() {
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight*1.2,
      behavior: 'smooth'
    });
  };
  return (
    <Container>
      <Row>
        <Col lg={8} md={6} sm={12}>
          <Image className="homeImage" src={HomeImage} fluid />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <h1 style={{fontWeight:"400"}} className="mobileHead">Welcome</h1>
          <p className="lead">
            to ACK power technology, where quality and craftsmanship comes first.
            We are a team of experienced{" "}
            <Link  style={{color:"#307BFF"}} to="/technicalEmployee"><b>professionals</b></Link> dedicated to creating
            beautiful and functional spaces for our clients. From custom homes
            to remodels and renovations, we work closely with our clients to
            bring their vision to life. Trust us to handle all of your
            construction and mechanical needs with precision and attention to
            details checkout our <Link  style={{color:"#307BFF"}} to="/awards"><b>awards</b></Link> and current
            ongoing <Link  style={{color:"#307BFF"}} to="/projects/0"><b>project</b></Link>
          </p>
          <Button onClick={handleScroll}>Our project and clients</Button>
          <Image style={{ marginTop: "30px" }} src={Team} fluid />
        </Col>
      </Row>
      <ClientSlide />
      <ProjectList />
    </Container>
  );
}
