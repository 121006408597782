import "./Contact.css"

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { infoData } from "../../Data";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CAMPAIGN_URL = "https://api.sendinblue.com/v3/smtp/email";
const API_KEY =
  "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";
// const sendEmailTo = "scientistboy109@gmail.com";
const sendEmailTo = "ackpowertec@gmail.com"
// const sendEmailTo = "echo.saurav@gmail.com";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const info = infoData();

  function isStringEmpty(str) {
    return str.length === 0;
  }

  function sendEmail(e) {
    e.preventDefault();
    toast.info("Sending email", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    if (isStringEmpty(content) || isStringEmpty(email)) {
      toast.error("Please insert your email and content", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    // const temp = {
    //   sender: {
    //     email: email,
    //   },
    //   to: [
    //     {
    //       email: sendEmailTo,
    //       name: "CEO",
    //     },
    //   ],
    //   templateId: 15,
    //   params: { content: content },
    //   subject: "Email from Ack Power Technology website",
    // };

    const html = `
    <html><head></head><body><h3>Someone just left a note for you in ACK Power Technology Website!</h3>
    <p><b>Email:</b> ${email}</p>
    <p><b>Message:</b>${content}</p>
    </body>
    </html>`;
    const temp = {
      sender: {
        email: email,
      },
      to: [
        {
          email: sendEmailTo,
          name: "CEO",
        },
      ],
      subject: "Email From ACK Power Tech Website contact us page",
      htmlContent: html,
    };

    //   const temp2={
    //     "sender":{
    //        "name":"Sender Alex",
    //        "email":"senderalex@example.com"
    //     },
    //     "to":[
    //        {
    //           "email":"testmail@example.com",
    //           "name":"John Doe"
    //        }
    //     ],
    //     "subject":"Email From ACK Power Tech Website",
    //     "htmlContent":"<html><head></head><body><p>Hello,</p>This is my first transactional email sent from Sendinblue.</p></body></html>"
    //  }

    fetch(CAMPAIGN_URL, {
      method: "POST",
      headers: { "api-key": API_KEY, "Content-Type": "application/json" },
      body: JSON.stringify(temp),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        toast.success("We received your feedback, Thank You! ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Email send failed, please check internet connection", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div style={{ margin: "20px" }}>
      <Row style={{ marginTop: "50px" }}>
        <Col lg={6} md={12} sm={12}>
          <div class="section-header">
            <h2>Contact Us</h2>
            <b>Office address</b>
            <p>Address: {info.address}</p>
            <a
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
              }}
              href={"https://goo.gl/maps/cB1Fqjhrva4534Hq5"}
            >
              Office location on google map
            </a>

            <br></br>
            <br></br>
            <p style={{ display: "inline", marginRight: "10px" }}>Phone:</p>
            {info.tel.map((num) => (
              <p style={{ display: "inline", marginRight: "10px" }}>{num}</p>
            ))}
            <p>
              <b> Email: {info.mail}</b>
            </p>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="content">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                // type="textarea"
                rows="3"
                placeholder="What you want to say to us..."
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={sendEmail}>
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
