import "./Resources.css"


import ceoImage from "../../media/peoples/ceo.jpeg";

export default function CEO() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>CEO</h2>
            <img style={{ width: "150px" }} src={ceoImage} />
            <h3 style={{ marginTop: "10px" }}>Engr. Nurul Islam</h3>
            <p className="lead">
              Engr. Nurul Islam had done Mechanical Diploma from ITE WSQ
              Singapore 2006-2008, Engineering (Mechanical Technology-2000) &
              Welding from German Technical Training Institute-2001, Mirpur
              Dhaka, Bangladesh.
            </p>
            <p className="lead">
              Having technical experiences in different countries he established
              “ACK Power Technology” since 2015, as a Proprietor.
            </p>
            <p className="lead">
              Before that Engr. Nurul Islam worked as Manager (Head of
              Mechanical) at TECHNO ASSOCIATES, Chittagong, Bangladesh till
              2012-2015.He also worked as a Welder Supervisor at BLNG Gas Plant
              under of MASHHOUR GENERAL CONTRACTOR SD Serai, Brunei from 2010 to
              2012. He also served as a Welder Supervisor at SIEMENS & DAEWOO
              E&C in Abu Dhabi, UAE from 2009 to 2010.In Keppel FELS Singapore
              he was a Welding Supervisor under of Comet Marine Engineering,
              from 2008 to 2009. He was Welder at PPL Shipyard Engineering in
              Singapore as a 6G Tig + Arc from 2007 to 2008. At Zamil Co
              Operations and Maintenance Com. Ltd. in Dammam, K.S.A he worked as
              a Downhill Welder, from 2006 to 2007 and Tig + Arc Multi Welder
              Keppel FELS in Azarvizen, Russia., From 2005 to 2006. Before that
              at Keppel FELS in Singapore he was a Welder Foreman from 2004 to
              2005.At Hyundai Engineering & Construction Co., Ltd. in
              Narayanganj - Bangladesh worked as a 6G Tig + Arc Multi Welder.
              From 2002 to 2003. In Gobelieq Haripur Power Ltd, Dhaka Bangladesh
              worked as 6G a SMAW Welder. In the year 2001.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
