import "./Resources.css"
import { useState } from "react";
import { Button } from "react-bootstrap";
import Loading from "../../Loading";

export default function FabricationYard(){
    
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>ACK Steel Factory</h2>
            <h1>{!isLoaded &&<Loading text={"Map loading, please wait..."}/>}</h1>
            <div>
              <div class="mapouter">
                <div class="gmap_canvas iframeWrapper">
                  <iframe
                    onLoad={handleLoad}
                    className={isLoaded ? "visible" : "hidden"}
                    width="100%"
                    height="600"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1791.8692648941628!2d90.7328783!3d23.9800481!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37542faa404679b7%3A0xc5b9f407ee951d41!2zSXRha2hvbGEgR29sIENvdHRvciDgpofgpp_gpr7gppbgprLgpr4g4KaX4KeL4KayIOCmmuCmpOCnjeCmpOCmsA!5e1!3m2!1sen!2sbd!4v1676794242558!5m2!1sen!2sbd"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

}