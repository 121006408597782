export default function RiskReduction() {
    return (
      <div>
        <section id="services">
          <div class="container">
            <div class="section-header">
              <h2>RISK REDUCTION PLAN EXAMPLES</h2>
              <div className="table-responsive">
              <table class="table table-striped ">
                <tbody>
                  <tr>
                    <td rowspan="2" valign="top">
                      <p>
                        <strong>ITEM</strong>
                        <strong>&nbsp;</strong>
                        <strong>NO.</strong>
                      </p>
                    </td>
                    <td rowspan="2" valign="top">
                      <p>
                        <strong>ACTIVITY/</strong>
                        <strong>&nbsp;</strong>
                        <strong>JOB</strong>
                        <strong>&nbsp;</strong>
                        <strong>STEP</strong>
                      </p>
                    </td>
                    <td rowspan="2" valign="top">
                      <p align="center">
                        <strong>HAZARDS</strong>
                      </p>
                      <p align="center">
                        <strong>(Potential&nbsp;</strong>
                        <strong>for</strong>
                        <strong>&nbsp;</strong>
                        <strong>Harm)</strong>
                      </p>
                    </td>
                    <td rowspan="2" valign="top">
                      <p align="center">
                        <strong>RISKS</strong>
                      </p>
                      <p align="center">
                        <strong>(Unwanted</strong>
                        <strong>&nbsp;</strong>
                        <strong>Event)</strong>
                      </p>
                    </td>
                    <td colspan="2" valign="top">
                      <p>
                        <strong>RISK</strong>
                        <strong>&nbsp;</strong>
                        <strong>REDUCTION</strong>
                        <strong>&nbsp;</strong>
                        <strong>PLAN</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>
                        <strong>SYSTEM</strong>
                        <strong>&nbsp;</strong>
                        <strong>CONTROLS</strong>
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        <strong>ADDITIONAL</strong>
                      </p>
                      <p>
                        <strong>CONTROLS</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>1</p>
                    </td>
                    <td valign="top">
                      <p>General</p>
                    </td>
                    <td valign="top">
                      <p>Labor work outdoors</p>
                    </td>
                    <td valign="top">
                      <p>Exposure to weather - cold, wind, sun, heat</p>
                    </td>
                    <td valign="top">
                      <p>Training and instruction. Exposure.</p>
                    </td>
                    <td valign="top">
                      <p>Scheduled Rest &amp;</p>
                      <p>Meal Breaks.</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>2</p>
                    </td>
                    <td valign="top">
                      <p>
                        Establishig&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; Access and Work Areas
                      </p>
                    </td>
                    <td valign="top">
                      <p>Personal Injury</p>
                    </td>
                    <td valign="top">
                      <p>Slope/Uneven Ground</p>
                    </td>
                    <td valign="top">
                      <p align="justify">
                        Training and instruction in <strong>SWI</strong>
                        -Housekeeping Access and Egress.
                      </p>
                    </td>
                    <td valign="top">
                      <p>Approvals in Work Place &amp;</p>
                      <p>PPE Controls</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Welding,</p>
                    </td>
                    <td rowspan="5" valign="top">
                      <ol>
                        <li>The risk management strategy.</li>
                        <li>Chances of A Workplace-Related Injury Decrease</li>
                      </ol>
                      <p>Dramatically.</p>
                    </td>
                    <td valign="top">
                      <p>Identifying potential hazards</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Electric Arc-</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Cutting, Grinding</p>
                    </td>
                    <td valign="top">
                      <p>before they can cause an injury or</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>3</p>
                    </td>
                    <td valign="top">
                      <p>Welding</p>
                    </td>
                    <td valign="top">
                      <p>Electric Shocks.</p>
                    </td>
                    <td valign="top">
                      <p>and Brazing</p>
                    </td>
                    <td valign="top">
                      <p>fire incident is the foundation</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Equipment.</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Equipment</p>
                    </td>
                    <td valign="top">
                      <p>upon which a risk management</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Operation.</p>
                    </td>
                    <td valign="top">
                      <p>Plan is built.</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>4</p>
                    </td>
                    <td valign="top">
                      <p>Electric Arc- Welding Equipment</p>
                    </td>
                    <td valign="top">
                      <p>Exposure to gases and fumes</p>
                    </td>
                    <td valign="top">
                      <p>
                        Welding operations release gases and fumes into the
                        immediate environment
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        3. Training employees on safety-oriented procedures and
                        processes
                      </p>
                      <p>
                        &ldquo;How Would You Prevent Welding-Related
                        Injuries?&rdquo;
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        PPE For Welders Can Include Fire- Retardant Clothing or
                        Aprons, Face Shields, Eye and Hearing Protection, Heavy
                        Gloves, And Steel-Toed Work Shoes, Only to Name A Few Of
                        the Many PPE Items Commonly Found in
                      </p>
                      <p>Welding Workplaces.</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Vehicles Should Never Be</p>
                    </td>
                    <td valign="top"></td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Displacement</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Overloaded. Loads Should</p>
                    </td>
                    <td valign="top"></td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>5</p>
                    </td>
                    <td valign="top">
                      <p>Piping Fabrication</p>
                    </td>
                    <td valign="top">
                      <p>of load</p>
                      <p>&amp; Failure of lifting</p>
                    </td>
                    <td valign="top">
                      <p>Laying of Pipes</p>
                    </td>
                    <td valign="top">
                      <p>Be Evenly Distributed, Secured and Not</p>
                      <p>Protruding Beyond the</p>
                    </td>
                    <td valign="top">
                      <p>
                        Lifting Equipment Inspection, Load Chart &amp; Signal
                        Checkup
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>equipment</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Sides or Back of The</p>
                    </td>
                    <td valign="top"></td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Vehicle</p>
                    </td>
                    <td valign="top"></td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>6</p>
                    </td>
                    <td valign="top">
                      <p>Piping erection</p>
                    </td>
                    <td valign="top">
                      <p>Laceration &amp;</p>
                      <p>Serious bodily injury</p>
                    </td>
                    <td valign="top">
                      <p>Slips, Trips &amp; Falls</p>
                    </td>
                    <td valign="top">
                      <p>
                        Workplace Tidiness Maintained by Cleaning Up During Work,
                        At End of Day to Avoid Slips and
                      </p>
                      <p>Trips</p>
                    </td>
                    <td valign="top">
                      <p>&ndash; Clean as you go Housekeeping.</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>7</p>
                    </td>
                    <td valign="top">
                      <p>Columns Erection.</p>
                    </td>
                    <td valign="top">
                      <p>Falling from Heights</p>
                      <p>&amp;</p>
                      <p>Being Hit by Falling Objects</p>
                    </td>
                    <td valign="top">
                      <p>Work at Hight &amp; Near miss</p>
                    </td>
                    <td valign="top">
                      <p>
                        A safe work method statement <strong>(SWMS)</strong>
                        <strong>&nbsp;</strong>must be prepared for high risk
                        construction work.
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        Keep body hearses &amp; using work platforms and{" "}
                        <strong>elevating work platforms</strong>
                        <strong>&nbsp;</strong>
                        <strong>(EWPs)</strong>
                        <strong>&nbsp;</strong>to prevent falls.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Eye Injuries from</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>workers be consulted</p>
                    </td>
                    <td rowspan="6" valign="top">
                      <p>Follow-up Trained Rigger Signals.</p>
                      <p>&amp;</p>
                      <p>
                        Maintenance, Operations of{" "}
                        <strong>Safe Work Load (SWL)</strong>
                        <strong>&nbsp;</strong>system
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Flying Metal</p>
                    </td>
                    <td valign="top">
                      <p>Not to use</p>
                    </td>
                    <td valign="top">
                      <p>and given the</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>8</p>
                    </td>
                    <td valign="top">
                      <p>Lifting Beams to Position.</p>
                    </td>
                    <td valign="top">
                      <p>Splinters</p>
                      <p>&amp;</p>
                      <p>Back and Spinal</p>
                    </td>
                    <td valign="top">
                      <p>PPE on work &amp; not to use</p>
                      <p>lifting</p>
                    </td>
                    <td valign="top">
                      <p>necessary information,</p>
                      <p>training, instruction and supervision to</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Injury from Lifting</p>
                    </td>
                    <td valign="top">
                      <p>Equipment&rsquo;s</p>
                    </td>
                    <td valign="top">
                      <p>minimize the risks of</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>and Moving</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Steel erection work.</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top">
                      <p>Heavy Loads</p>
                    </td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>9</p>
                    </td>
                    <td valign="top">
                      <p>Lifting Object Failure</p>
                    </td>
                    <td valign="top">
                      <p>Sensor &amp; Mechanical fault</p>
                    </td>
                    <td valign="top">
                      <p>Lifting Operation Failure Due Gear</p>
                      <p>Faults</p>
                    </td>
                    <td valign="top">
                      <p>Current load Capacity Of Lifting Gear,</p>
                      <p>Ensure Mechanical Errors and Load</p>
                      <p>Indicators</p>
                    </td>
                    <td valign="top">
                      <p>
                        3<sup>rd</sup> party inspection Certificate.
                      </p>
                      <p>
                        Mechanical Engine Operation Systems Checklist Monitoring.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>10</p>
                    </td>
                    <td valign="top">
                      <p>Collapse Of Mobile Crane &amp; Equipment&rsquo;s</p>
                    </td>
                    <td valign="top">
                      <p>
                        Lifting Operation in Intense Wind or Before Storm &amp;
                        Weak ground load capacity.
                      </p>
                    </td>
                    <td valign="top">
                      <p>Suspend Lifting Operation If Wind Speed Exceeded 80</p>
                      <p>Km/Ph</p>
                    </td>
                    <td valign="top">
                      <p>Explain Wind Speed Limiter While in Lifting</p>
                    </td>
                    <td valign="top">
                      <p>Understand Moment Of Force &amp; wind.</p>
                      <p>Consideration as The Relate to The Movement</p>
                      <p>Of Objects.</p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>11</p>
                    </td>
                    <td valign="top">
                      <p>Sandblasting works</p>
                    </td>
                    <td valign="top">
                      <p>
                        Air pollution &amp; Noise Pollution, Metallic
                        dust/Splinters.
                      </p>
                    </td>
                    <td valign="top">
                      <p>Respiratory problems &amp; Audiometric problems</p>
                    </td>
                    <td valign="top">
                      <p>
                        Apply proper blasting procedure. Not Working with the old
                        equipment.
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        Ensure PPE Using the Musk, safety glass. Also Using the
                        especial ear plug and ear muff during the work.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">
                      <p>12</p>
                    </td>
                    <td valign="top">
                      <p>Painting works</p>
                    </td>
                    <td valign="top">
                      <p>Potential vapor inhalation</p>
                    </td>
                    <td valign="top">
                      <p align="justify">Constant paint and chemical contact</p>
                    </td>
                    <td valign="top">
                      <p>
                        Work in ventilated areas to avoid paint and thinner
                        vapors.
                      </p>
                    </td>
                    <td valign="top">
                      <p>
                        Wear proper gloves and eye protection. Injury and Illness
                        Prevention
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  