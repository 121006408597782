import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from './components/gal/Gallery';
import Home from './components/home/Home';
import Health from "./components/hse/Health";
import Navigation from './components/nav/Navigation';
import NotFound from './components/notfound/NotFound';
import HSEIntro from './components/hse/HSEIntro'
import ProjectHealth from "./components/hse/ProjectHealth"
import ManagementCommitment from "./components/hse/ManagementCommitment";
import OrganizationAndResources from "./components/hse/OrganizationAndResources";
import JobSafety from "./components/hse/JobSafety";
import Training from "./components/hse/Trainning"
import Hazard from "./components/hse/Hazard"
import RiskReduction from "./components/hse/RiskReduction"
import SafetyRecord from "./components/hse/SafetyRecord"
import SafetyAudit from "./components/hse/SafetyAudit";
import Introduction from "./components/aboutUs/Introduction";
import CEOnotes from "./components/aboutUs/CEOnotes";
import Awards from "./components/aboutUs/Awards";
import CEO from "./components/Resources/CEO"
import EquipmentAndTools from "./components/Resources/EquipmentAndTools"
import TechnicalEmployee from "./components/Resources/TechnicalEmployee"
import FabricationYard from "./components/Resources/FabricationYard"
import Capabilities from "./components/home/Capabilities";
import Contact from "./components/contact/Contact";
import Projects from "./components/projects/Projects";
import { Container } from "react-bootstrap";
import Footer from "./components/footer/Footer";
import BackgroundCarousel from "./components/back/BackgroundCarousel";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import 'react-photo-view/dist/react-photo-view.css';

export const navdata = [
  {
    name: "About Us",
    nav: [
      { name: "Introduction", path: "/intro", element: <Introduction /> },
      { name: "CEO's notes", path: "/ceonotes", element: <CEOnotes /> },
      { name: "Award and achievement", path: "/awards", element: <Awards /> },
    ]
  },
  {
    name: "Resources",
    nav: [
      { path: "/ceo", name: "CEO", element: <CEO /> },
      { path: "/equipments", name: "Equipments and tools", element: <EquipmentAndTools /> },
      { path: "/fabricationYard", name: "ACK Steel Factory", element: <FabricationYard /> },
      { path: "/technicalEmployee", name: "Technical Employee", element: <TechnicalEmployee /> },
    ]
  },

  {
    name: "HSE",
    nav: [
      { path: "/hseInto", name: "HSE Introduction", element: <HSEIntro /> },
      { path: "/healthenvironment", name: "HEALTH, ENVIRONMENT AND SAFETY MANAGEMENT PLAN", element: <Health /> },
      { path: "/projectHealth", name: "Project Health, Safety and Environment Plan", element: <ProjectHealth /> },
      { path: "/managementcommitment", name: "Management commitment", element: <ManagementCommitment /> },
      { path: "/organizationAndResources", name: "Orientation And Resources and development", element: <OrganizationAndResources /> },
      { path: "/JobSafety", name: "Job Safety", element: <JobSafety /> },
      { path: "/Trainning", name: "Training", element: <Training /> },
      { path: "/Hazard", name: "Hazard Identification and risk assessment", element: <Hazard /> },
      { path: "/RiskReduction", name: "Risk reduction plan example", element: <RiskReduction /> },
      { path: "/SafetyRecord", name: "Safety records", element: <SafetyRecord /> },
      { path: "/SafetyAudit", name: "Safety audits", element: <SafetyAudit /> },
    ]
  },

]


const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <>
      <BackgroundCarousel />
      <Router>
        <Container className="app cardHome" fluid>
          <Wrapper>
            <Navigation/>
            <div className="containerHome">
              <Routes>
                <Route path="/" element={<Home />} />
                {/* About Us */}
                {navdata[0].nav.map(item => (
                  <Route path={item.path} element={item.element} />
                ))}
                {/* Projects */}
                <Route path="/projects/:id" element={<Projects />} />
                {/* Resources */}
                {navdata[1].nav.map(item => (
                  <Route path={item.path} element={item.element} />
                ))}

                {/* HSE */}
                {navdata[2].nav.map(item => (
                  <Route path={item.path} element={item.element} />
                ))}
                <Route path="/capabilities" element={<Capabilities />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>

            <Footer />
          </Wrapper>
        </Container>

      </Router>

    </>
  );
}

export default App;
