import { Container, Card, Button, Row } from "react-bootstrap";
import { ProjectData } from "../../Data";
import Ram from "./ram.jpeg";
import Sam from "./sam.jpeg";
import Ghora from "./ghora.PNG";
import { Link } from "react-router-dom";
import "./Home.css"


export default function ProjectList() {


  const projects = ProjectData();
  const img = [Ghora, Sam, Ram];

  return (
    <Container>
      <div style={{ textAlign: "center" }}>
        <h1>Recent Projects</h1>
        <p className="lead" style={{ maxWidth: "550px", margin: "auto", paddingBottom: "20px" }}>
          Discover the possibilities with us. Our team of experts will bring
          your construction dreams to life with quality and efficiency.
        </p>
      </div>
      <Row className="justify-content-md-center">
        {projects
          .reverse()
          .slice(0, 3)
          .map((item, i) => (
            <Card style={{ width: "18rem", margin: "10px" }}>
              <Card.Img
                className="cardImage"
                variant="top"
                src={img[i]}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Text>{item.desc}</Card.Text>

                <Link   to={`/projects/${i}/`}>
                  <Card.Link style={{color:"#307BFF"}}>See details</Card.Link>
                </Link>
              </Card.Body>
            </Card>
          ))}
      </Row>
    </Container>
  );
}
