import "./AboutUs.css"

import ceoImage from "../../media/peoples/ceo.jpeg";

export default function CeoNotes() {
  return (
    <div>
      <section id="services">
        <div class="container">
          <div class="section-header">
            <h2>Note from CEO</h2>
            <img style={{ width: "150px" }} src={ceoImage} />
            <h3 style={{ marginTop: "10px" }}>Engr. Nurul Islam</h3>
            <p className="lead">
              It gives me great pleasure to welcome you to the Website of ACK
              POWER TECHNOLOGY. Our company is highly capable of undertaking
              Mechanical and Industrial works. Our core Team members have
              wide-ranging educational backgrounds and professional experiences
              from Institutions/Organizations at home and abroad. We are able to
              provide services that meet the Engineering and Quality
              requirements of International Engineering Classification
              societies.
            </p>
            <p className="lead">
              Our main focus is to ensure quality, time and customer
              satisfaction with our skilled man-power and highly experienced
              management. Therefore, we are very receptive to customer feedback
              and suggestions. Based on the information given in this website,
              we hope you will be impressed with our capabilities and will
              engage or recommend us for any of your requirements that falls
              within our areas of expertise.
            </p>
            <p className="lead">Thank you.</p>

            <p className="lead"><b >Engr. Nurul Islam</b></p>
          </div>
        </div>
      </section>
    </div>
  );
}
